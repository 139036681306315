import { Program } from "../program";
import { System } from "../script";

export class Cat extends Program {
  public async execute(term: System, args?: string[]) {
    if (args.length == 0) {
      term.printError("cat: no arguments provided");
      return;
    }

    const path = term.filesystem.splitPath(args[0], term.cwd);
    const node = term.filesystem.resolve(path);

    console.log(path);

    if (node == null) {
      term.printError(`cat: cannot access "/${path.join("/")}": no such file or directory`);
      return;
    }

    switch (node.type) {
      case "directory":
        term.printError(`cat: cannot access "/${path.join("/")}": is a directory`);
        break;
      case "link":
        term.print(`Opening "/${path.join("/")}" ...`);
        document.location = node.url;
        break;
      case "file":
        term.print(`${node.content}`);
        break;
    }
  }

  public autocomplete(term: System, args: string[]): string[] {
    const parts = term.filesystem.splitPath(args.length > 0 ? args[0] : term.cwd + "/", term.cwd);
    return term.filesystem.autocomplete(parts);
  }
}
