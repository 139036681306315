import { Program } from "../program";
import { System } from "../script";

export class Cd extends Program {
  public async execute(term: System, args?: string[]) {
    if (args.length == 0) {
      term.cwd = "/home/" + term.usr;
      return;
    }

    const parts = term.filesystem.splitPath(
      args[0].startsWith("~") ? `/home/${term.usr}/${args[0].substring(1)}` : args[0],
      term.cwd
    );
    const node = term.filesystem.resolve(parts);

    if (node == null) {
      term.printError(`cd: cannot access "/${parts.join("/")}": no such file or directory`);
      return;
    }

    switch (node.type) {
      case "directory":
        term.cwd = "/" + parts.join("/");
        break;
      case "link":
        document.location = node.url;
        break;
      case "file":
        term.printError(`cd: cannot access "/${parts.join("/")}": not a directory`);
        break;
    }
  }

  public autocomplete(term: System, args: string[]): string[] {
    const parts = term.filesystem.splitPath(args.length > 0 ? args[0] : term.cwd + "/", term.cwd);
    return term.filesystem.autocomplete(parts);
  }
}
