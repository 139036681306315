import { Program } from "../program";
import { System } from "../script";

interface Application {
  name: string;
  url: string;
}

export class Launch extends Program {
  private readonly _targets: Map<string, Application> = new Map([
    [
      "github",
      {
        name: "GitHub",
        url: "https://github.com/lmichaelis",
      },
    ],
    [
      "gitlab",
      {
        name: "GitLab",
        url: "https://gitlab.com/lmichaelis",
      },
    ],
  ]);

  public async execute(term: System, args?: string[]) {
    if (args.length < 1) {
      term.printError("launch: no arguments provided");
      return;
    }

    if (args[0] == "-h" || args[0] == "--help") {
      term.print(`<pre>
USAGE
    launch [OPTION] [APPLICATION]

DESCRIPTION
    Launch APPLICATION in the current window.

OPTIONS
    -h, --help      Print this help text
    -l, --list      List all available applications
</pre>`);

      return;
    }

    if (args[0] == "-l" || args[0] == "--list") {
      term.print("Available applications:");

      for (const [_, app] of this._targets) {
        term.print(`- <a href="${app.url}">${app.name}</a>`);
      }
      return;
    }

    const app = this._targets.get(args[0].toLowerCase());

    if (app == undefined) {
      term.printError(`launch: application "${args[0]}" not found`);
      return;
    }

    term.print(`Launching ${args} ...`);
    document.location = app.url;
  }

  autocomplete(term: System, args: string[]): string[] {
    const complete = args.pop() ?? "";
    return Array.from(this._targets.keys()).filter((s) => s.startsWith(complete));
  }
}
