export interface FileSystemDirNode {
  type: "directory";
  children: { [name: string]: FileSystemNode };
}

export interface FileSystemLinkNode {
  type: "link";
  url: string;
}

export interface FileSystemFileNode {
  type: "file";
  content: string;
}

export interface FileSystemDeviceNode {
  type: "device";
  read: () => string;
  write: (text: string) => boolean;
}

export type FileSystemNode = FileSystemDirNode | FileSystemLinkNode | FileSystemFileNode;

const fsDir = (children: { [name: string]: FileSystemNode }): FileSystemDirNode => {
  return {
    type: "directory",
    children,
  };
};

const fsFile = (content: string): FileSystemFileNode => {
  return {
    type: "file",
    content,
  };
};

const fsLink = (url: string): FileSystemLinkNode => {
  return {
    type: "link",
    url,
  };
};

export class FileSystem {
  private _root: FileSystemDirNode = fsDir({
    home: fsDir({
      luis: fsDir({
        Papers: fsDir({
          "ssd.pdf": fsLink("https://triton.lmichaelis.de/paper/ssd.pdf"),
        }),
        Projects: fsDir({
          Archive: fsDir({
            "Computer-Simulation": fsLink("https://github.com/lmichaelis/Computer-Simulation"),
            "rtc-driver-rpi": fsLink("https://github.com/lmichaelis/rtc-driver-rpi"),
            nebel: fsLink("https://gitlab.com/lmichaelis/nebel"),
          }),
          Abandoned: fsDir({
            lynx: fsLink("https://gitlab.com/lynx-mc"),
          }),
          ZenKit: fsLink("https://github.com/GothicKit/ZenKit"),
          ZenKitCS: fsLink("https://github.com/GothicKit/ZenKitCS"),
          ZenKit4J: fsLink("https://github.com/GothicKit/ZenKit4J"),
          dmusic: fsLink("https://github.com/GothicKit/dmusic"),
          "dmusic-cs": fsLink("https://github.com/GothicKit/dmusic-cs"),
          "phoenix-studio": fsLink("https://github.com/lmichaelis/phoenix-studio"),
          aurora: fsLink("https://github.com/OrbisMinecraft/aurora"),
        }),
        "readme.txt": fsFile("Who are You, Who are so wise in the ways of the Terminal?"),
      }),
      anon: fsDir({}),
    }),
  });

  private _traverseTree(path: string[]): FileSystemNode | null {
    let current = this._root;

    do {
      let child = current.children[path[0]];

      if (child == undefined) return null;
      if (path.length == 1) return child;

      if (child.type != "directory") return null;
      current = child;
      path = path.slice(1);
    } while (path.length > 0);

    return null;
  }

  public resolve(path: string[]): FileSystemNode | null {
    if (path.length == 0 || path[0].length == 0) {
      return this._root;
    }

    return this._traverseTree(path.filter((s) => s.length > 0));
  }

  public splitPath(path: string, cwd: string): string[] {
    if (!path.startsWith("/")) {
      path = cwd + (cwd.endsWith("/") ? "" : "/") + path;
    }

    let split = path.split("/").filter((s, i, orig) => (s.length > 0 || i == orig.length - 1) && s != ".");

    // Normalize ..
    let idx = split.findIndex((s) => s == "..");
    while (idx >= 0) {
      split = split.filter((_, i) => i != idx - 1 && i != idx);
      idx = split.findIndex((s) => s == "..");
    }

    return split;
  }

  public autocomplete(path: string[]): string[] {
    const parentPath = path.slice(0, -1);
    const parentNode = this.resolve(parentPath);

    if (parentNode == null || parentNode.type != "directory") {
      return [];
    }

    return Object.keys(parentNode.children)
      .filter((s) => s.startsWith(path[path.length - 1]))
      .map((v) => {
        if (parentPath.length > 0) {
          return "/" + parentPath.join("/") + "/" + v;
        }
        return "/" + v;
      });
  }
}
