import { Program } from "../program";
import { System } from "../script";
import { FileSystemNode } from "../filesystem";

export class Ls extends Program {
  private _printNode(term: System, name: string, node: FileSystemNode) {
    if (node.type == "directory") {
      term.print(`- <b class='padded-text text-blue'>${name}</b>`);
    } else if (node.type == "link") {
      term.print(`- <a href='${node.url}'>${name}</a> -> <span class="text-gray">${node.url}</span>`);
    } else {
      term.print(`- <span class='padded-text'>${name}</span>`);
    }
  }

  public async execute(term: System, args?: string[]) {
    const parts = term.filesystem.splitPath(args.length > 0 ? args[0] : term.cwd, term.cwd);
    const node = term.filesystem.resolve(parts);

    if (node == null) {
      term.printError(`ls: cannot access "/${parts.join("/")}": no such file or directory`);
      return;
    }

    if (node.type == "directory") {
      for (const ch in node.children) {
        this._printNode(term, ch, node.children[ch]);
      }
    } else {
      this._printNode(term, parts[parts.length - 1], node);
    }
  }

  public autocomplete(term: System, args: string[]): string[] {
    const parts = term.filesystem.splitPath(args.length > 0 ? args[0] : term.cwd + "/", term.cwd);
    return term.filesystem.autocomplete(parts);
  }
}
