import {AutocompleteContext, Program} from "../program";
import {System} from "../script";
import {Launch} from "./launch";

// @ts-ignore
import img from "../../resources/profile.png";
import {Ls} from "./ls";
import {Cat} from "./cat";
import {Cd} from "./cd";

class ShellClear extends Program {
  public async execute(term: System, args?: string[]) {
    term.clear();
  }
}

class ShellHelp extends Program {
  public async execute(term: System, args?: string[]) {
    term.printWarning("Uh oh ... I can't seem find any help pages! Ah well, you'll manage, right?");
  }
}

class ShellWelcome extends Program {
  public async execute(term: System, args?: string[]) {
    term.print(`
<table class="p-0 m-0">
<tbody>
  <tr>
    <td class="p-0 pr-10 m-0"><img width="256" src='${img}'></td>
    <td style="vertical-align: top">
      <div><span class="text-purple">me</span>@<span class="text-purple">lmichaelis.de</span></div>
      <div class="mt-3">Hi, I'm Luis, a full-stack software developer from Germany!</div>
      <div>Here are some interesting links:</div>
      <ul>
          <li><a href="https://github.com/lmichaelis">GitHub</a></li>
          <li><a href="https://gitlab.com/lmichaelis">GitLab</a></li>
          <li><a href="https://lmichaelis.de/upl/papers/ssd_flash.pdf">A random paper about SSDs</a></li>
      </ul>
      <div>Feel free to roam around this page,</div>
      <div>maybe you'll find something cool :)</div>
    </td>
  </tr>
</tbody>
</table>
<p style="border: 1px var(--gruvbox-red) solid; padding: 1rem; margin-right: 1em">
  <span>New project released: <a href="https://dm.gothickit.dev/">A partial re-implementation of DirectMusic</a>, 
  Micro$oft's discontinued real-time music playback sytem.</span>
</p>
`);
  }
}

export class Shell extends Program {
  private _autocomplete = new AutocompleteContext();
  private _commandHistory: string[] = [];
  private _commandHistoryIndex: number = 0;

  private readonly _commands: { [name: string]: Program } = {
    clear: new ShellClear(),
    help: new ShellHelp(),
    welcome: new ShellWelcome(),
    launch: new Launch(),
    ls: new Ls(),
    cat: new Cat(),
    cd: new Cd(),
  };

  private async _run_command(term: System, name: string, args?: string[]) {
    const cmd = this._commands[name];
    if (cmd == undefined) {
      term.printError(`shell: command not found: ${name}`);
      return;
    }

    await cmd.execute(term, args);
  }

  public onKey(term: System, key: string) {
    this._autocomplete.clear();

    if (key == "ArrowUp") {
      if (this._commandHistoryIndex < 0 || this._commandHistory.length == 0) return;
      term.setInput(this._commandHistory[this._commandHistoryIndex--]);
    } else if (key == "ArrowDown") {
      if (this._commandHistoryIndex >= this._commandHistory.length - 1 || this._commandHistory.length == 0) return;
      term.setInput(this._commandHistory[++this._commandHistoryIndex]);
    } else {
      this._commandHistoryIndex = this._commandHistory.length - 1;
    }
  }

  public async execute(term: System, args?: string[]) {
    await this._run_command(term, "welcome");

    while (true) {
      const prompt = `<span class="text-bg4">${term.usr}</span>:<span class="text-blue-muted">${term.cwd}</span> $`;
      const stmt = await term.prompt(prompt);
      term.print(prompt + " " + stmt);

      if (stmt.length == 0) {
        continue;
      }

      const parts = stmt.split(" ");
      if (parts.length == 0) {
        continue;
      }

      await this._run_command(term, parts[0], parts.slice(1));

      this._commandHistory.push(stmt);
      this._commandHistoryIndex = this._commandHistory.length - 1;
    }
  }

  public autocomplete(term: System, args: string[]): string[] {
    const parts = term.getInput().split(" ");

    if (this._autocomplete.isSet()) {
      const completion = this._autocomplete.next();
      if (completion == undefined) return;

      parts[parts.length - 1] = completion;
      term.setInput(parts.join(" "));
      return;
    } else if (parts.length < 2) {
      const last = parts.pop();
      this._autocomplete.set(Object.keys(this._commands).filter((v) => v.startsWith(last)));

      const completion = this._autocomplete.next();
      if (completion == undefined) return;

      parts.push(completion);
      term.setInput(parts.join(" "));
    } else {
      const cmd = this._commands[parts[0]];
      if (cmd == undefined) {
        return [];
      }

      this._autocomplete.set(cmd.autocomplete(term, parts.slice(1)));

      const completion = this._autocomplete.next();
      if (completion == undefined) return;
      parts[parts.length - 1] = completion;
      term.setInput(parts.join(" "));
    }

    return [];
  }
}
