import { System } from "./script";

export abstract class Program {
  public abstract execute(term: System, args?: string[]): Promise<void>;

  public autocomplete(term: System, args: string[]): string[] {
    return [];
  }
}

export class AutocompleteContext {
  private _choices: string[] = [];
  private _index: number = 0;
  private _set: boolean = false;

  public isSet() {
    return this._set;
  }

  public clear() {
    this._choices = [];
    this._index = 0;
    this._set = false;
  }

  public set(choices: string[]) {
    this._choices = choices;
    this._set = true;
    this._index = 0;
  }

  public next(): string {
    return this._choices[this._index++ % this._choices.length];
  }
}
