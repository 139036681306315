import { Shell } from "./programs/shell";
import { FileSystem } from "./filesystem";

export class System {
  private _input: HTMLInputElement;
  private _locator: HTMLLabelElement;
  private _output: HTMLDivElement;

  public filesystem: FileSystem = new FileSystem();
  public cwd: string = "/home/luis";
  public usr: string = "anon";

  private _shell: Shell;
  private _io: (string) => void | null = null;

  public constructor() {
    this._input = document.getElementById("qi-input") as HTMLInputElement;
    this._locator = document.getElementById("qi-locator") as HTMLLabelElement;
    this._output = document.getElementById("qi-output") as HTMLDivElement;

    // If JS is enabled, make the input field visible
    document.getElementById("qi-input-container").classList.remove("display-none");

    this._setup();
  }

  private _setup() {
    this._input.onkeydown = (ev) => {
      if (ev.key == "Enter") {
        this._io(this._input.value);
        this._input.value = "";
      } else if (ev.key == "Tab") {
        this._shell.autocomplete(this, []);
        ev.preventDefault();
        ev.stopPropagation();
      } else {
        this._shell.onKey(this, ev.key);
      }
    };

    // Enter shell
    this._shell = new Shell();
    this._shell.execute(this).then();
  }

  public async prompt(q: string): Promise<string> {
    return new Promise<string>((resolve) => {
      this.setPrompt(q);
      this._io = (input) => resolve(input);
    });
  }

  public setFocus() {
    this._input.focus();
  }

  public setPrompt(text: string) {
    this._locator.innerHTML = text;
  }

  public getPrompt(): string {
    return this._locator.innerHTML;
  }

  public clear() {
    this._output.innerHTML = "";
  }

  public getInput() {
    return this._input.value;
  }

  public setInput(text: string) {
    this._input.value = text;
  }

  public print(text: string) {
    const node = document.createElement("div");
    node.innerHTML = text;
    this._output.appendChild(node);
    this._scrollToBottom();
  }

  public printError(text: string) {
    const node = document.createElement("div");
    node.classList.add("text-red");
    node.innerHTML = text;
    this._output.appendChild(node);
    this._scrollToBottom();
  }

  public printWarning(text: string) {
    const node = document.createElement("div");
    node.classList.add("text-yellow-muted");
    node.innerHTML = text;
    this._output.appendChild(node);
    this._scrollToBottom();
  }

  private _scrollToBottom() {
    this._input.scrollIntoView({ block: "end" });
  }
}

window.onload = () => {
  const terminal = new System();

  // Automatically focus the input field regardless of where the user clicks.
  window.onclick = (_) => {
    terminal.setFocus();
  };
};
